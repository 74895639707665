import React, { ChangeEvent, useState } from "react";

import {
  Button,
  Grid,
  InputLabel,
  Paper,
  TextField,
  Typography
} from "@mui/material";

import MaskedInput from "src/components/ui/MaskedInput/MaskedInput";
import { User } from "src/models/User";
import { useAppDispatch } from "src/store/hooks";
import { emailOnchange, firstNameOnchange, lastNameOnchange, phoneOnchange, setLoading } from "src/store/reducers/editUser/editUserSlice";
import { getUser } from "src/store/thunks/user/get/getUser";

import DisableUserDialog from "../../ListUsers/DisableUserDialog/DisableUserDialog";
import ResetPasswordDialog from "../ResetPasswordDialog/ResetPasswordDialog";

import useStyles from "./UserInformation.styles";

interface UserInformationProps {
    handleSubmit: Function;
    values: any;
    handleUpdate: Function;
    touched: any;
    errors: any;
    editUser?: User;
}

const UserInformation: React.FC<UserInformationProps> = ({ handleSubmit, values, handleUpdate, touched, errors, editUser }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [disableUserOpen, setDisableUserOpen] = useState(false);

  const handleFirstNameOnchange = (
    fieldName: string,
    fieldValue: string
  ) => {
    handleUpdate(fieldName, fieldValue);
    dispatch(firstNameOnchange(fieldValue));
  };

  const handleLastNameOnchange = (fieldName: string, fieldValue: string) => {
    handleUpdate(fieldName, fieldValue);
    dispatch(lastNameOnchange(fieldValue));
  };

  const handleEmailOnchange = (fieldName: string, fieldValue: string) => {
    handleUpdate(fieldName, fieldValue);
    dispatch(emailOnchange(fieldValue));
  };

  const handlePhoneOnchange = (fieldName: string, fieldValue: string) => {
    handleUpdate(fieldName, fieldValue);
    dispatch(phoneOnchange(fieldValue));
  };

  const handleUpdateUser = () => {
    dispatch(setLoading(true));
    dispatch(getUser()).then((resp) => {
      if (resp.type.includes("fulfilled")) {
        dispatch(setLoading(false));
      }
    });
  };

  return (
    <form
      data-testid={"edit-user-form"}
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit();
      }}>
      <Paper elevation={4} className={classes.paper}>
        <Grid container>
          <Grid item xs={12} className={classes.title}>
            <Typography variant={"h6"} >
              User Information
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {errors.editUserAssignments && <Typography className={classes.assignmentError}>{errors.editUserAssignments}</Typography>}
          </Grid>
          <Grid item xs={12} mb={2}>
            <InputLabel htmlFor={"edit-user-first-name"}>
              <Typography variant={"subtitle2"}>First Name</Typography>
            </InputLabel>
            <TextField
              fullWidth
              id={"edit-user-first-name"}
              data-testid={"edit-user-first-name"}
              name={"editUserFirstName"}
              value={values.editUserFirstName}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleFirstNameOnchange("editUserFirstName", e.target.value)
                  }
              error={
                    touched &&
                    touched.editUserFirstName &&
                    errors &&
                    Boolean(errors.editUserFirstName)
                  }
              helperText={
                    touched &&
                    touched.editUserFirstName &&
                    errors &&
                    errors.editUserFirstName
                  }
                />
          </Grid>
          <Grid item xs={12} mb={2}>
            <InputLabel htmlFor={"edit-user-last-name"}>
              <Typography variant={"subtitle2"}>Last Name</Typography>
            </InputLabel>
            <TextField
              fullWidth
              id={"edit-user-last-name"}
              data-testid={"edit-user-last-name"}
              inputProps={{ "data-testid": "edit-user-last-name" }}
              name={"editUserLastName"}
              value={values.editUserLastName}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleLastNameOnchange("editUserLastName", e.target.value)
                  }
              error={touched && touched.editUserLastName && errors && Boolean(errors.editUserLastName)}
              helperText={
                    touched &&
                    touched.editUserLastName &&
                    errors &&
                    errors.editUserLastName
                  }
              />
          </Grid>
          <Grid item xs={12} mb={2}>
            <InputLabel htmlFor={"edit-user-username"}>
              <Typography variant={"subtitle2"}>Username</Typography>
            </InputLabel>
            <TextField
              fullWidth
              id={"edit-user-username"}
              data-testid={"edit-user-username"}
              inputProps={{ "data-testid": "edit-user-username" }}
              disabled={true}
              name={"editUserUsername"}
              value={values.editUserUsername}
              />
          </Grid>
          <Grid item xs={12} mb={2}>
            <InputLabel htmlFor={"edit-user-email"}>
              <Typography variant={"subtitle2"}>Email</Typography>
            </InputLabel>
            <TextField
              fullWidth
              id={"edit-user-email"}
              data-testid={"edit-user-email"}
              inputProps={{ "data-testid": "edit-user-email" }}
              name={"editUserEmail"}
              value={values.editUserEmail}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleEmailOnchange("editUserEmail", e.target.value)
              }
              error={touched && touched.editUserEmail && errors && Boolean(errors.editUserEmail)}
              helperText={
                    touched &&
                    touched.editUserEmail &&
                    errors &&
                    errors.editUserEmail
                  }
              />
          </Grid>
          <Grid item xs={12} mb={2}>
            <InputLabel htmlFor={"edit-user-phone"}>
              <Typography variant={"subtitle2"}>Phone Number</Typography>
            </InputLabel>
            <MaskedInput
              fullWidth
              id={"edit-user-phone"}
              data-testid={"edit-user-phone"}
              name={"editUserPhone"}
              type={"tel"}
              value={values.editUserPhone}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handlePhoneOnchange("editUserPhone", e.target.value)
                  }
              error={
                    touched &&
                    touched.editUserPhone &&
                    errors &&
                    Boolean(errors.editUserPhone)
                  }
              helperText={
                    touched &&
                    touched.editUserPhone &&
                    errors &&
                    errors.editUserPhone
                  }
              />
          </Grid>
          <Grid item xs={12} className={classes.buttonContainer} >
            <Button variant={"outlined"} className={classes.actionButtons} onClick={() => setForgotPasswordOpen(true)}>
              <Typography>Reset Password</Typography>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography>When manually resetting the password, the user will receive an email with a link to reset their password.</Typography>
          </Grid>
          <Grid item xs={12} className={classes.buttonContainer}>
            <Button variant={"outlined"} className={classes.actionButtons} onClick={() => setDisableUserOpen(true)}>
              <Typography>{editUser?.is_active ? "Deactivate User" : "Activate User"} </Typography>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography>By deactivating a user, they will lose access to the platform. After deactivation the user will be deleted if not reinstated within 30 days</Typography>
          </Grid>
        </Grid>
      </Paper>
      <ResetPasswordDialog open={forgotPasswordOpen} setOpen={setForgotPasswordOpen}/>
      <DisableUserDialog open={disableUserOpen} setOpen={setDisableUserOpen} user={editUser}
        handleUpdate={handleUpdateUser}/>
    </form>);
};

export default UserInformation;
