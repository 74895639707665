import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: "3rem",
    marginTop: "1rem",
    boxShadow: "none",
    borderRadius: "10px"
  },
  actionButtons: {
    margin: "20px 0px 10px",
    width: "100%"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center"
  },
  assignmentError: {
    marginTop: "20px",
    color: theme.palette.error.main
  },
  title: {
    borderBottom: `1px solid ${theme.gray500Base}`,
    paddingBottom: "10px",
    marginBottom: "25px"
  }
}));

export default useStyles;
