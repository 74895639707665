import { createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance as axios } from "../../../../api/axiosInstance";
import { showSnackbar } from "../../../reducers/snackbar/snackbarSlice";

export const getOperatorUserInfo = createAsyncThunk(
  "getOperatorUserInfo",
  async(operatorId: string | undefined, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    return axios.get(`/api/v1/operators/${operatorId}`)
      .then(resp => resp.data)
      .catch(() => {
        const errorMessage = `There was an error getting the information for Operator ${operatorId}`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
